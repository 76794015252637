import React from "react";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import featureStyle from "assets/jss/material-kit-react/views/landingPageSections/featureStyle.jsx";



class EditHere extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
      <GridContainer className={classes.featureWhite}>
        <GridItem xs={12} lg={3}>
          <div className={classes.featureRightInfo}>

          <h2 className={classes.title}>Edit Here</h2>


          <h4 className={classes.description}>
            Forget exporting and emailing your manuscript for editing.
          </h4>
          <h4 className={classes.description}>
             Give permission to your favorite editor to make changes within an editor's version of your manuscript, automatically generated by BookMerlin.
          </h4>
          <h4 className={classes.description}>
            Each change, suggested by your editor, can be individually accepted or rejected by you.
          </h4>
          <h4 className={classes.description}>
            After editing is complete, you can automatically transfer some or all of the changes directly into your manuscript.
          </h4>

            </div>
        </GridItem>
        <GridItem xs={12} lg={9} className={classes.exampleGrid}>
          <div  className={classes.exampleImageRight}>
                <img
                    src={require('pages/HomePage/img/features_editing4.jpg')}
                     className={classes.featureLeftImg}/>
          </div>
        </GridItem>

      </GridContainer>
      </div>
    );
  }
}

export default withStyles(featureStyle)(EditHere);
