import React from "react";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import featureStyle from "assets/jss/material-kit-react/views/landingPageSections/featureStyle.jsx";



class WriteHere extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer className={classes.featureGrey}>
          <GridItem xs={12} lg={3}>
                <div className={classes.featureRightInfo}>
                <h2 className={classes.title}>Write Here</h2>


                <h4 className={classes.description}>
                  Write within a comfortable interface on the device of your choice.
                </h4>
                <h4 className={classes.description}>
                   Import an existing word document or start a fresh manuscript.
                </h4>
                <h4 className={classes.description}>
                  Color code and tag your content in keeping with your organizing style.
                </h4>
                <h4 className={classes.description}>
                  Insert configurable front and back matter with a click.
                </h4>

              {/*
                  <Link to='/feature' className={classes.link}>
                    More Features...
                  </Link>
              */}

                </div>
            </GridItem>
          <GridItem xs={12} lg={9} className={classes.exampleGrid}>
            <div className={classes.exampleImageLeft}>
                <img
                    src={require('pages/HomePage/img/features_writing3.jpg')}
                    className={classes.featureLeftImg}
                />
            </div>
          </GridItem>

        </GridContainer>
      </div>
    );
  }
}

export default withStyles(featureStyle)(WriteHere);
