import { container, title } from "assets/jss/material-kit-react.jsx";

const comingSoonStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    marginBottom: "1rem",
    //marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
  },
  subtitle: {
    fontSize: "1.313rem",
    margin: "10px 50px 30px 50px"
  },
  description: {
    color: "#999",
    textAlign: "center",
    paddingBottom: "5em"
  },
  greetingText: {
    color: "#777",
    textAlign: "left",
    paddingLeft:'1em',
    paddingRight:'1em'
    //textIndent: '2em'
  },
  section: {
    padding: "70px 0",
    textAlign: "center",
    width: "100%",
  },
  gridContainer: {
    borderx:'1px solid red',

  },
  gridItem: {
    marginTop: ".1em",
    display: "flex",
    flexDirection:'column',
    justifyContent: "space-between",
    alignItems: "center",
    borderx:'1px solid blue'
  },
  launch: {

  },
  launchHeader: {
    fontSize: "1.313rem",
    //margin: "1em auto 0",
    fontWeight: "bolder",
  },
  launchDescription: {
    color: "#999",
    textAlign: "center",
    paddingBottom: "5em",
  },
  float: {

  },
  floatHeader: {
    fontSize: "2.313rem",
    //margin: "1em auto 0",
    fontWeight: "bold",
  },
  floatWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  floatDescription: {
    color: "#fff",
    textAlign: "center",
    paddingBottom: "2em",
    fontWeight: "bolder",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    // margin: "-60px 30px 0px",
    // borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  headlineText:{
    fontSize:'.75em',
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    fontFamily: 'helvetica',
    fontWeight: '100',
    textAlign:'left'
  },
  headlineBullet:{
    fontSize:'.60em',
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    fontFamily: 'helvetica',
    fontWeight: '100',

  },
  headlineMain:{
    displayx: 'flex',
    widthx: '100%',
    justifyContent: 'center',
    fontSize: '2.0em',
    color:'#8e44ad'
  },
  headlineMainContent:{
    fontSize:'1.6em',
    color:'#f39c12',
    borderx:'1px solid yellow',
    lineHeight:'1.2',
    fontWeight:'500',
    textAlign:'left',
    marginRight:'1.5em'
  },
  headlineSubMain:{
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    fontSize: '1.5em'
  },
  sidelineMain: {
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'flex-start',
    alignItems:'flex-start',
    fontSize: '2em',
    marginBottom:'1em',
    borderx:'1px solid yellow',
    width:'100%'
  },
  sidelineMainLeft: {
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'flex-start',
    alignItems:'flex-start',
    marginBottom:'3em',
    borderx:'1px solid yellow',
    width:'100%',
    height:'100%'
  },
  sidelineHeader: {
    borderBottom:'4px solid #f39c12',
    paddingBottom:'.2em',
    marginBottomx:'.5em'

  },
  sidelineContent: {
    borderBottomx:'4px solid #009688',
    paddingBottomx:'.5em',
    fontSize: '.6em',

  },
  navLink: {
    fontWeight: "bolder",
    color: "white",
    '&:hover': {
      color: '#0000ee',
    },
    '&:visited': {
      color: 'white',
    },
  },
};

export default comingSoonStyle;
